import styled from 'styled-components'

export const SingleImageContainer = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const StyledImage = styled.img`
  width: 100%;
  max-width: 1008px;
  height: 100%;
`
